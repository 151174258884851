const burgerBtn = document.querySelector('.burger-btn')
const burgerLines = document.querySelectorAll('.burger-btn-line')
const navLinks = document.querySelectorAll('.nav__link')
const logo = document.querySelector('.nav__logo')
const navLinksBox = document.querySelector('.nav__links')
const footerSpan = document.querySelector('.footer__year')
const langBox = document.querySelector('.language-mobile')
const mainLang = document.querySelector('.main-lang')
const secondLang = document.querySelector('.lang-second')

const counterBox = document.querySelector('.header__stats')
const circles = [...document.querySelectorAll('.icon__circle')]

const nameInput = document.querySelector('#name')
const emailInput = document.querySelector('#email')
const contentInput = document.querySelector('#textarea')
const formBtn = document.querySelector('.contact__btn')
const btn2 = document.querySelector('.contact__btn')
const submitBtn = document.querySelector('.contact__btn-2')
const locInp = document.querySelector('.inp-loc')
const form = document.querySelector('form')

if (document.body.id === 'main') {
	const speed = 500

	const startCounter = entry => {
		if (entry[0].isIntersecting) {
			function initCountUp() {
				const counters = document.querySelectorAll('.counter')
				counters.forEach(function (counter, index) {
					const delay = 10
					const time = 1000
					const isThird = index === 2 // check if it's the third element
					const formattedFinalValue = counter.dataset.number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

					// Add dot to the third element before starting the animation
					if (isThird) {
						const variable = counter.innerHTML
						const formattedVariable = variable.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
						counter.innerHTML = formattedVariable
					}

					var countUp = function () {
						var value = parseInt(counter.innerHTML.replace(/\./g, ''))
						var end = parseInt(counter.dataset.number)
						var increment = end / (time / delay)

						if (value < end) {
							counter.innerHTML = Math.ceil(value + increment)

							if (value + increment >= end && isThird) {
								// Check if this is the third element and if the counter has reached its final value
								counter.innerHTML = formattedFinalValue
							} else if (isThird) {
								const variable = counter.innerHTML
								const formattedVariable = variable.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
								counter.innerHTML = formattedVariable
							}

							setTimeout(countUp, delay)
						} else {
							counter.innerHTML = formattedFinalValue
						}
					}

					countUp()
				})
			}
			initCountUp()
		}
	}
	const options = {
		rootMargin: '-100px',
	}
	const observer = new IntersectionObserver(startCounter, options)
	observer.observe(counterBox)
}

const handleNav = () => {
	burgerLines.forEach(line => {
		line.classList.toggle('opened')
	})
	navLinksBox.classList.toggle('active')
	deleteClassOnDesktop()
}

const closeNav = () => {
	burgerLines.forEach(line => {
		line.classList.remove('opened')
	})
	navLinksBox.classList.remove('active')
}

const deleteClassOnDesktop = () => {
	if (window.innerWidth > 992) {
		closeNav()
	}
}

const showExtendedLangBox = () => {
	langBox.classList.toggle('opened')
	secondLang.classList.toggle('lang-visible')
}

const changeURL = e => {
	location.href = e.target.dataset.path
}

const current__year = () => {
	const date = new Date()
	const year = date.getFullYear()
	footerSpan.textContent = year
}
current__year()

navLinks.forEach(link => {
	link.addEventListener('click', handleNav)
})

const setProps = e => {
	const div = e.target.parentElement.parentElement
	const heading = div.querySelector('.how__heading')
	heading.classList.add('howered-heading')
	e.target.classList.add('hovered')
}
const delProps = e => {
	const div = e.target.parentElement.parentElement
	const heading = div.querySelector('.how__heading')
	heading.classList.remove('howered-heading')
	e.target.classList.remove('hovered')
}
let isTouchedName = false
let isTouchedEmail = false
let isTouchedTextarea = false

const validateNameInput = () => {
	isTouchedName = true
	if (nameInput.value.trim() === '') {
		nameInput.nextElementSibling.classList.add('error-visible')
		nameInput.classList.add('has-error')
	} else {
		nameInput.nextElementSibling.classList.remove('error-visible')
		nameInput.classList.remove('has-error')
	}
	checkErrors()
}

const validateTextarea = () => {
	isTouchedTextarea = true
	if (contentInput.value.trim() === '') {
		contentInput.nextElementSibling.classList.add('error-visible')
		contentInput.classList.add('has-error')
	} else {
		contentInput.nextElementSibling.classList.remove('error-visible')
		contentInput.classList.remove('has-error')
	}
	checkErrors()
}

const validateInput = () => {
	isTouchedEmail = true
	let isEmpty = false
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	if (emailInput.value.trim() === '') {
		isEmpty = true
		emailInput.classList.add('has-error')
		emailInput.nextElementSibling.nextElementSibling.classList.add('error-visible')
	} else {
		emailInput.nextElementSibling.nextElementSibling.classList.remove('error-visible')
		emailInput.classList.remove('has-error')
	}
	if (isEmpty) {
		emailInput.nextElementSibling.classList.remove('error-visible')
		return
	}

	if (!re.test(emailInput.value)) {
		emailInput.nextElementSibling.classList.add('error-visible')
		emailInput.classList.add('has-error')
	} else {
		emailInput.nextElementSibling.classList.remove('error-visible')
		emailInput.classList.remove('has-error')
	}
	checkErrors()
}

const checkErrors = () => {
	const errorPs = document.querySelectorAll('.error-visible')
	let isValid = false
	if (isTouchedEmail && isTouchedName && isTouchedTextarea) {
		if (errorPs.length > 0) {
			isValid = false
		} else {
			isValid = true
		}
	}
	return isValid
}

const canBeSubmitted = e => {
	e.preventDefault()

	validateInput()
	validateTextarea()
	validateNameInput()
	const isValidForm = checkErrors()

	if (isValidForm) {
		grecaptcha.ready(function () {
			grecaptcha.execute('6LfemfUlAAAAAAaU4rcVL0zytfV_WbdSOGWFWk5g', { action: 'contact' }).then(function (token) {
				const elms = document.getElementsByClassName('recaptcha_response')
				for (let i = 0; i < elms.length; i++) {
					elms[i].setAttribute('value', token)
				}

				btn2.innerHTML = '<div id="loading-bar-spinner" class="spinner"><div class="spinner-icon"></div></div>'

				var formData = new FormData(form)

				var xhr = new XMLHttpRequest()

				xhr.open('POST', '../mail.php')

				xhr.onreadystatechange = function () {
					if (xhr.readyState === 4) {
						if (xhr.status === 200) {
							btn2.innerHTML = btn2.dataset.trans
							var successDiv = document.querySelector('.form-message.success')
							successDiv.style.display = 'block'
							form.reset()
						} else {
							btn2.innerHTML = btn2.dataset.trans
							var errorDiv = document.querySelector('.form-message.error-d')
							errorDiv.style.display = 'block'
						}
					}
				}

				xhr.send(formData)
			})
		})
	}
}

form.addEventListener('submit', canBeSubmitted)
emailInput.addEventListener('input', validateInput)
nameInput.addEventListener('input', validateNameInput)
contentInput.addEventListener('input', validateTextarea)

circles.forEach(circle => {
	circle.addEventListener('mouseover', setProps)
})
circles.forEach(circle => {
	circle.addEventListener('mouseleave', delProps)
})
secondLang.addEventListener('click', changeURL)
mainLang.addEventListener('click', showExtendedLangBox)
burgerBtn.addEventListener('click', handleNav)
logo.addEventListener('click', closeNav)
window.addEventListener('resize', deleteClassOnDesktop)
